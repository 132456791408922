import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useRouter } from "next/router";
import Footer from "../Footer";
import Header from "../Header";
import { setCurrentItemMenu } from "@/store/hud/hudSlice";
import { ILayoutProps } from "./interfaces";

import styles from "./styles.module.scss";

const Layout = ({ children }: ILayoutProps) => {
  const dispatch = useDispatch();
  const { pathname } = useRouter();

  useEffect(() => {
    dispatch(setCurrentItemMenu(pathname));
  }, [pathname]);

  return (
    <>
      <div className={styles.layout}>
        <Header />
        <main className={styles.main}>{children}</main>
        <Footer />
      </div>
    </>
  );
};

export default Layout

