import { useSelector } from "react-redux";
import BoostDispaly from "@/components/BoostDispaly";
import { RootState } from "@/store";
import { formatTime } from "@/utils/formatters/formatTime";
import Image from "next/image";
import React, { useMemo } from "react";
import plus from "@/assets/icons/plus.png";
import { CoinItemProps } from "./interfaces";

import styles from "./styles.module.scss";

const CoinItem: React.FC<CoinItemProps> = ({
  iconSrc,
  value,
  altText,
  onClick,
}) => {
  return (
    <div className={styles.coin__wrapper}>
      <Image
        className={styles.coin__icon}
        src={iconSrc}
        alt={altText}
        width={35}
        loading="lazy"
        placeholder="blur"
        height={35}
      />
      <div className={styles.coin__value__wrapper}>
        <span className={styles.coin__value}>{value}</span>
      </div>
      {onClick && (
        <Image
          className={styles.coin__icon__add}
          src={plus}
          alt={`${altText} button add`}
          onClick={onClick}
          width={28}
          loading="lazy"
          placeholder="blur"
          height={28}
        />
      )}
    </div>
  );
};

export default React.memo(CoinItem);
