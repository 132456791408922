import React from "react";
import { RootState } from "@/store";
import Image from "next/image";
import CardInitials from "../Cards/CardInitials";
import { IAvatarProps } from "./interfaces";
import hat from "@/assets/hud/header/hat.png";
import { useSelector } from "react-redux";


import styles from "./styles.module.scss";


const Avatar = ({
  className,
  classNameHat,
  classNameImage,
}: IAvatarProps) => {
  const username = useSelector((state: RootState) => state.user.username);
  return (
    <div className={className ? className : styles.avatar__wrapper}>
      <Image
        className={classNameHat ? classNameHat : styles.hat}
        src={hat}
        alt="hat on avatar"
        loading="lazy"
        placeholder="blur"
      />

      <CardInitials
        className={classNameImage ? "" : styles.avatar__initials}
        username={username}
      />
    </div>
  );
}

export default React.memo(Avatar)
