import React, { useCallback, useMemo } from "react";
import CoinItem from "@/components/CoinItem";
import BoostDispaly from "@/components/BoostDispaly";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";
import { RootState } from "@/store";
import tonIcon from "@/assets/icons/ton.png";
import coin from "@/assets/icons/coin.png";
import pearlsIcon from "@/assets/icons/pearls.png";
import { setCurrentPage } from "@/store/shop/shopSlice";
import { formatCoin } from "@/utils/formatters/formatCoin";
import { formatTime } from "@/utils/formatters/formatTime";
import useClickSound from "@/hooks/useClickSound";

import styles from "./styles.module.scss";

const CoinDisplay = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const playSound = useClickSound();
  const ton = useSelector((state: RootState) => state.user.balance.ton);
  const pearls = useSelector((state: RootState) => state.user.balance.pearls);
  const coins = useSelector((state: RootState) => state.user.balance.coins);

  const isBoosted =
    useSelector(
      (state: RootState) => state.user.balance.multipliers_boosters.booster
    ) != "0";

  const handleClickAddPearls = useCallback(() => {
    playSound();
    dispatch(setCurrentPage("ЖЕМЧУГ"));
    router.push("/shop");
  }, [playSound, dispatch, router]);

  return (
    <div className={styles.coins__wrapper}>
      <div className={styles.coins__container}>
        <CoinItem
          iconSrc={tonIcon}
          value={formatCoin(ton, true)}
          altText="ton"
        />
        <CoinItem
          iconSrc={pearlsIcon}
          value={formatCoin(pearls)}
          altText="pearl"
          onClick={handleClickAddPearls}
        />
        <div className={styles.wrapper__boost}>
          <CoinItem iconSrc={coin} value={formatCoin(coins)} altText="coin" />
          {isBoosted && <BoostDispaly />}
        </div>
      </div>
    </div>
  );
};

export default React.memo(CoinDisplay);
